import { render, staticRenderFns } from "./CommonPageShell.vue?vue&type=template&id=0d3f3928&scoped=true"
import script from "./CommonPageShell.vue?vue&type=script&lang=js"
export * from "./CommonPageShell.vue?vue&type=script&lang=js"
import style0 from "./CommonPageShell.vue?vue&type=style&index=0&id=0d3f3928&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d3f3928",
  null
  
)

export default component.exports