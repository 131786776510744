<template>
  <mds-page-shell
    :logo="require('@/assets/logos/morningstar.svg')"
    :logo-responsive="require('@/assets/logos/morningstar.svg')"
    logo-href="/"
    :class="containerName !== 'empty' ? 'dap' : ''"
    logo-alt="Back to home page"
  >
    <template #mds-page-shell-masthead-right>
      <div class="rigth-content">
        <env-tag />
        <div class="buttons-container">
          <mds-button
            v-if="canViewUserManagementComputed"
            id="with-multi-sections-trigger"
            variation="icon-only"
            icon="gear"
            type="button"
            @click="toggle=!toggle"
          />
          <mds-popover
            v-model="toggle"
            triggered-by="with-multi-sections-trigger"
            :position="['bottom-left']"
          >
            <mds-section
              class="with-nested-sections"
              bold
              :size="7"
              border="none"
              title="Options"
            >
              <mds-list-group>
                <mds-list-group-item @click.native="redirect(routes.USER_MANAGEMENT)">
                  User Management <mds-icon name="person-group" />
                </mds-list-group-item>
              </mds-list-group>
            </mds-section>
          </mds-popover>
          <mds-button
            variation="flat"
            icon="person"
            flat-button-icon-size="medium"
            @click="logout"
          >
            Sign Out
          </mds-button>
        </div>
      </div>
    </template>
    <template
      v-if="tabsSiteNavContent.length"
      #mds-page-shell-horizontal-nav
    >
      <site-navigation :tabs-site-nav-content="tabsSiteNavContent" />
    </template>
    <template #default>
      <div :class="containerName !== 'empty' ? 'dap__container' : ''">
        <slot />
      </div>
    </template>
  </mds-page-shell>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsIcon from '@mds/icon';
import MdsPopover from '@mds/popover';
import MdsSection from '@mds/section';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsPageShell from '@mds/page-shell';
import SiteNavigation from '@/components/layout/SiteNavigation';
import { MODULE, COMMON } from '@/constants/store.constant';
import AUTH from '@/constants/auth.constant';
import ROUTES from '@/constants/routes.constant';
import { mapGetters } from 'vuex';
import PERMISSIONS from '@/constants/permissions.constant';
import EnvTag from '@/components/ui/EnvironmentTag.vue';

export default {
  name: 'CommonPageShell',
  components: {
    MdsPageShell,
    SiteNavigation,
    MdsButton,
    MdsIcon,
    MdsPopover,
    MdsSection,
    MdsListGroup,
    MdsListGroupItem,
    EnvTag,
  },
  props: {
    containerName: {
      type: String,
      default: '',
    },
    tabsSiteNavContent: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      toggle: false,
      routes: ROUTES,
    };
  },
  computed: {
    ...mapGetters({
      canViewUserManagementGetter: `${MODULE.PERMISSIONS}/${COMMON.GET_STATE}`,
    }),
    canViewUserManagementComputed () {
      return this.canViewUserManagementGetter(PERMISSIONS.USER_MANAGEMENT);
    },
  },
  methods: {
    logout () {
      this.$store.dispatch(`${MODULE.AUTH}/${AUTH.LOG_OUT}`);
    },
    redirect (name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.dap {
  background-color: $mds-background-color-white;

  &__container {
    border: $mds-border-separator;
    border-radius: $mds-border-radius-panel;
    min-height: 100vh;
    margin: $mds-space-4-x auto;
    padding: $mds-space-4-x !important;
  }
}
.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rigth-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

::v-deep .mds-layout-grid__row__dap-crawler {
  margin-bottom: $mds-space-3-x;
}

::v-deep thead {
  text-align: left;
}
</style>
