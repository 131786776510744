<template>
  <common-page-shell :tabs-site-nav-content="tabsSiteNavContent">
    <router-view />
  </common-page-shell>
</template>

<script>
import CommonPageShell from './CommonPageShell.vue';
import ROUTES from '@/constants/routes.constant';

export default {
  name: 'PageShellUserManagement',
  components: {
    CommonPageShell,
  },
  data () {
    return {
      tabsSiteNavContent: [
        {
          text: 'Users',
          id: ROUTES.USER_MANAGEMENT.LIST,
          active: true,
        },
        {
          text: 'Tenants',
          id: ROUTES.USER_MANAGEMENT.TENANTS,
          active: false,
        }
      ],
    };
  },
};
</script>
